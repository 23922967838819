/*
*
* Preloader
*/
.preloader {
 position: fixed;
 left: 0;
 top: 0;
 bottom: 0;
 right: 0;
 z-index: 10000;
 display: -ms-flexbox;
 display: flex;
 -ms-flex-pack: center;
 justify-content: center;
 -ms-flex-align: center;
 align-items: center;
 padding: 20px;
 background: #ffffff;
 transition: .3s all ease;
}
.preloader.loaded {
 opacity: 0;
 visibility: hidden;
}
.bookshelf-wrapper {
 position: relative;
}
.books-list {
 margin: 0 auto;
 width: 150px;
 padding: 0;
}
@media (min-width: 768px) {
 .books-list {
  width: 225px;
 }
}
.book-item {
 position: absolute;
 top: -60px;
 box-sizing: border-box;
 list-style: none;
 width: 20px;
 height: 60px;
 opacity: 0;
 background-color: transparent;
 border: 2px solid #000000;
 -webkit-transform-origin: bottom left;
 transform-origin: bottom left;
 -webkit-transform: translateX(150px);
 transform: translateX(150px);
 -webkit-animation: travel-mobile 2500ms linear infinite;
 animation: travel-mobile 2500ms linear infinite;
}
.book-item.first {
 top: -70px;
 height: 70px;
}
.book-item.first:before,
.book-item.first:after {
 content: '';
 position: absolute;
 left: 0;
 width: 100%;
 height: 2px;
 background-color: #000000;
}
.book-item.first:before {
 top: 5px;
}
.book-item.first:after {
 bottom: 5px;
}
.book-item.second:before,
.book-item.second:after,
.book-item.fifth:before,
.book-item.fifth:after {
 box-sizing: border-box;
 content: '';
 position: absolute;
 left: 0;
 width: 100%;
 height: 7px;
 border-top: 2px solid #000000;
 border-bottom: 2px solid #000000;
}
.book-item.second:before,
.book-item.fifth:before {
 top: 5px;
}
.book-item.second:after,
.book-item.fifth:after {
 bottom: 5px;
}
.book-item.third:before,
.book-item.third:after {
 box-sizing: border-box;
 content: '';
 position: absolute;
 left: 4.5px;
 width: 6px;
 height: 6px;
 border-radius: 50%;
 border: 2px solid #000000;
}
.book-item.third:before {
 top: 5px;
}
.book-item.third:after {
 bottom: 5px;
}
.book-item.fourth {
 top: -65px;
 height: 65px;
}
.book-item.fourth:before {
 box-sizing: border-box;
 content: '';
 position: absolute;
 top: 23px;
 left: 0;
 width: 100%;
 height: 7px;
 border-top: 2px solid #000000;
 border-bottom: 2px solid #000000;
}
.book-item.fifth {
 top: -50px;
 height: 50px;
}
.book-item.sixth {
 top: -70px;
 height: 70px;
}
.book-item.sixth::before,
.book-item.sixth::after {
 box-sizing: border-box;
 content: '';
 position: absolute;
}
.book-item.sixth:before {
 bottom: 15.5px;
 left: 0;
 width: 100%;
 height: 2px;
 background-color: #000000;
}
.book-item.sixth:after {
 bottom: 5px;
 left: 4.5px;
 width: 6px;
 height: 6px;
 border-radius: 50%;
 border: 2px solid #000000;
}
.book-item:nth-child(2) {
 -webkit-animation-delay: 416.66667ms;
 animation-delay: 416.66667ms;
}
.book-item:nth-child(3) {
 -webkit-animation-delay: 833.33333ms;
 animation-delay: 833.33333ms;
}
.book-item:nth-child(4) {
 -webkit-animation-delay: 1250ms;
 animation-delay: 1250ms;
}
.book-item:nth-child(5) {
 -webkit-animation-delay: 1666.66667ms;
 animation-delay: 1666.66667ms;
}
.book-item:nth-child(6) {
 -webkit-animation-delay: 2083.33333ms;
 animation-delay: 2083.33333ms;
}
@media (min-width: 768px) {
 .book-item {
  top: -90px;
  width: 30px;
  height: 90px;
  border-width: 3px;
  -webkit-transform: translateX(225px);
  transform: translateX(225px);
  -webkit-animation-name: travel;
  animation-name: travel;
 }
 .book-item.first {
  top: -105px;
  height: 105px;
 }
 .book-item.first:before,
 .book-item.first:after {
  height: 3px;
 }
 .book-item.first::before {
  top: 7.5px;
 }
 .book-item.first:after {
  bottom: 7.5px;
 }
 .book-item.second:before,
 .book-item.second:after,
 .book-item.fifth:before,
 .book-item.fifth:after {
  height: 10.5px;
  border-top-width: 3px;
  border-bottom-width: 3px;
 }
 .book-item.second::before,
 .book-item.fifth::before {
  top: 7.5px;
 }
 .book-item.second:after,
 .book-item.fifth:after {
  bottom: 7.5px;
 }
 .book-item.third:before,
 .book-item.third:after {
  left: 6.75px;
  width: 9px;
  height: 9px;
  border-width: 3px;
 }
 .book-item.third::before {
  top: 7.5px;
 }
 .book-item.third:after {
  bottom: 7.5px;
 }
 .book-item.fourth {
  top: -97.5px;
  height: 97.5px;
 }
 .book-item.fourth:before {
  top: 34.5px;
  height: 10.5px;
  border-top-width: 3px;
  border-bottom-width: 3px;
 }
 .book-item.fifth {
  top: -75px;
  height: 75px;
 }
 .book-item.sixth {
  top: -105px;
  height: 105px;
 }
 .book-item.sixth:before {
  bottom: 23.25px;
  height: 3px;
 }
 .book-item.sixth:after {
  bottom: 7.5px;
  left: 6.75px;
  width: 9px;
  height: 9px;
  border-width: 3px;
 }
}
.shelf {
 width: 150px;
 height: 2px;
 margin: 0 auto;
 background-color: #000000;
 position: relative;
}
.shelf:before,
.shelf:after {
 content: '';
 position: absolute;
 width: 100%;
 height: 100%;
 background: transparent;
 background-image: radial-gradient(rgba(0, 0, 0, 0.5) 30%, transparent 0);
 background-size: 10px 10px;
 background-position: 0 -2.5px;
 top: 200%;
 left: 5%;
 -webkit-animation: move 250ms linear infinite;
 animation: move 250ms linear infinite;
}
.shelf:after {
 top: 400%;
 left: 7.5%;
}
@media (min-width: 768px) {
 .shelf {
  width: 225px;
  height: 3px;
 }
}
@-webkit-keyframes move {
 from {
  background-position-x: 0;
 }
 to {
  background-position-x: 10px;
 }
}
@keyframes move {
 from {
  background-position-x: 0;
 }
 to {
  background-position-x: 10px;
 }
}
@-webkit-keyframes travel-mobile {
 0% {
  opacity: 0;
  -webkit-transform: translateX(150px) rotateZ(0deg) scaleY(1);
  transform: translateX(150px) rotateZ(0deg) scaleY(1);
 }
 6.5% {
  -webkit-transform: translateX(139.75px) rotateZ(0deg) scaleY(1.1);
  transform: translateX(139.75px) rotateZ(0deg) scaleY(1.1);
 }
 8.8% {
  -webkit-transform: translateX(136.8px) rotateZ(0deg) scaleY(1);
  transform: translateX(136.8px) rotateZ(0deg) scaleY(1);
 }
 10% {
  opacity: 1;
  -webkit-transform: translateX(135px) rotateZ(0deg);
  transform: translateX(135px) rotateZ(0deg);
 }
 17.6% {
  -webkit-transform: translateX(123.6px) rotateZ(-30deg);
  transform: translateX(123.6px) rotateZ(-30deg);
 }
 45% {
  -webkit-transform: translateX(82.5px) rotateZ(-30deg);
  transform: translateX(82.5px) rotateZ(-30deg);
 }
 49.5% {
  -webkit-transform: translateX(75.75px) rotateZ(-45deg);
  transform: translateX(75.75px) rotateZ(-45deg);
 }
 61.5% {
  -webkit-transform: translateX(57.75px) rotateZ(-45deg);
  transform: translateX(57.75px) rotateZ(-45deg);
 }
 67% {
  -webkit-transform: translateX(49.5px) rotateZ(-60deg);
  transform: translateX(49.5px) rotateZ(-60deg);
 }
 76% {
  -webkit-transform: translateX(36px) rotateZ(-60deg);
  transform: translateX(36px) rotateZ(-60deg);
 }
 83.5% {
  opacity: 1;
  -webkit-transform: translateX(24.75px) rotateZ(-90deg);
  transform: translateX(24.75px) rotateZ(-90deg);
 }
 90% {
  opacity: 0;
 }
 100% {
  opacity: 0;
  -webkit-transform: translateX(0px) rotateZ(-90deg);
  transform: translateX(0px) rotateZ(-90deg);
 }
}
@keyframes travel-mobile {
 0% {
  opacity: 0;
  -webkit-transform: translateX(150px) rotateZ(0deg) scaleY(1);
  transform: translateX(150px) rotateZ(0deg) scaleY(1);
 }
 6.5% {
  -webkit-transform: translateX(139.75px) rotateZ(0deg) scaleY(1.1);
  transform: translateX(139.75px) rotateZ(0deg) scaleY(1.1);
 }
 8.8% {
  -webkit-transform: translateX(136.8px) rotateZ(0deg) scaleY(1);
  transform: translateX(136.8px) rotateZ(0deg) scaleY(1);
 }
 10% {
  opacity: 1;
  -webkit-transform: translateX(135px) rotateZ(0deg);
  transform: translateX(135px) rotateZ(0deg);
 }
 17.6% {
  -webkit-transform: translateX(123.6px) rotateZ(-30deg);
  transform: translateX(123.6px) rotateZ(-30deg);
 }
 45% {
  -webkit-transform: translateX(82.5px) rotateZ(-30deg);
  transform: translateX(82.5px) rotateZ(-30deg);
 }
 49.5% {
  -webkit-transform: translateX(75.75px) rotateZ(-45deg);
  transform: translateX(75.75px) rotateZ(-45deg);
 }
 61.5% {
  -webkit-transform: translateX(57.75px) rotateZ(-45deg);
  transform: translateX(57.75px) rotateZ(-45deg);
 }
 67% {
  -webkit-transform: translateX(49.5px) rotateZ(-60deg);
  transform: translateX(49.5px) rotateZ(-60deg);
 }
 76% {
  -webkit-transform: translateX(36px) rotateZ(-60deg);
  transform: translateX(36px) rotateZ(-60deg);
 }
 83.5% {
  opacity: 1;
  -webkit-transform: translateX(24.75px) rotateZ(-90deg);
  transform: translateX(24.75px) rotateZ(-90deg);
 }
 90% {
  opacity: 0;
 }
 100% {
  opacity: 0;
  -webkit-transform: translateX(0px) rotateZ(-90deg);
  transform: translateX(0px) rotateZ(-90deg);
 }
}
@-webkit-keyframes travel {
 0% {
  opacity: 0;
  -webkit-transform: translateX(225px) rotateZ(0deg) scaleY(1);
  transform: translateX(225px) rotateZ(0deg) scaleY(1);
 }
 6.5% {
  -webkit-transform: translateX(209.625px) rotateZ(0deg) scaleY(1.1);
  transform: translateX(209.625px) rotateZ(0deg) scaleY(1.1);
 }
 8.8% {
  -webkit-transform: translateX(205.2px) rotateZ(0deg) scaleY(1);
  transform: translateX(205.2px) rotateZ(0deg) scaleY(1);
 }
 10% {
  opacity: 1;
  -webkit-transform: translateX(202.5px) rotateZ(0deg);
  transform: translateX(202.5px) rotateZ(0deg);
 }
 17.6% {
  -webkit-transform: translateX(185.4px) rotateZ(-30deg);
  transform: translateX(185.4px) rotateZ(-30deg);
 }
 45% {
  -webkit-transform: translateX(123.75px) rotateZ(-30deg);
  transform: translateX(123.75px) rotateZ(-30deg);
 }
 49.5% {
  -webkit-transform: translateX(113.625px) rotateZ(-45deg);
  transform: translateX(113.625px) rotateZ(-45deg);
 }
 61.5% {
  -webkit-transform: translateX(86.625px) rotateZ(-45deg);
  transform: translateX(86.625px) rotateZ(-45deg);
 }
 67% {
  -webkit-transform: translateX(74.25px) rotateZ(-60deg);
  transform: translateX(74.25px) rotateZ(-60deg);
 }
 76% {
  -webkit-transform: translateX(54px) rotateZ(-60deg);
  transform: translateX(54px) rotateZ(-60deg);
 }
 83.5% {
  opacity: 1;
  -webkit-transform: translateX(37.125px) rotateZ(-90deg);
  transform: translateX(37.125px) rotateZ(-90deg);
 }
 90% {
  opacity: 0;
 }
 100% {
  opacity: 0;
  -webkit-transform: translateX(0px) rotateZ(-90deg);
  transform: translateX(0px) rotateZ(-90deg);
 }
}
@keyframes travel {
 0% {
  opacity: 0;
  -webkit-transform: translateX(225px) rotateZ(0deg) scaleY(1);
  transform: translateX(225px) rotateZ(0deg) scaleY(1);
 }
 6.5% {
  -webkit-transform: translateX(209.625px) rotateZ(0deg) scaleY(1.1);
  transform: translateX(209.625px) rotateZ(0deg) scaleY(1.1);
 }
 8.8% {
  -webkit-transform: translateX(205.2px) rotateZ(0deg) scaleY(1);
  transform: translateX(205.2px) rotateZ(0deg) scaleY(1);
 }
 10% {
  opacity: 1;
  -webkit-transform: translateX(202.5px) rotateZ(0deg);
  transform: translateX(202.5px) rotateZ(0deg);
 }
 17.6% {
  -webkit-transform: translateX(185.4px) rotateZ(-30deg);
  transform: translateX(185.4px) rotateZ(-30deg);
 }
 45% {
  -webkit-transform: translateX(123.75px) rotateZ(-30deg);
  transform: translateX(123.75px) rotateZ(-30deg);
 }
 49.5% {
  -webkit-transform: translateX(113.625px) rotateZ(-45deg);
  transform: translateX(113.625px) rotateZ(-45deg);
 }
 61.5% {
  -webkit-transform: translateX(86.625px) rotateZ(-45deg);
  transform: translateX(86.625px) rotateZ(-45deg);
 }
 67% {
  -webkit-transform: translateX(74.25px) rotateZ(-60deg);
  transform: translateX(74.25px) rotateZ(-60deg);
 }
 76% {
  -webkit-transform: translateX(54px) rotateZ(-60deg);
  transform: translateX(54px) rotateZ(-60deg);
 }
 83.5% {
  opacity: 1;
  -webkit-transform: translateX(37.125px) rotateZ(-90deg);
  transform: translateX(37.125px) rotateZ(-90deg);
 }
 90% {
  opacity: 0;
 }
 100% {
  opacity: 0;
  -webkit-transform: translateX(0px) rotateZ(-90deg);
  transform: translateX(0px) rotateZ(-90deg);
 }
}
